const logotext = "Et3niTy";

const meta = {
    title: "Ananta Khanal",
    description: "Data Scientist & Full Stack Developer specializing in AI/ML solutions and web applications",
};

const introdata = {
    title: "I'm Ananta Khanal",
    animated: {
        first: "Data Scientist",
        second: "Web Developer",
        third: "ML Engineer",
        fourth: "Problem Solver",
        fifth: "Tech Innovator ⚡"
    },
    description: "Transforming data into actionable insights and building scalable web solutions",
    your_img_url: "https://i.ibb.co/L0ZS4qT/pic-2.jpg",
};

const dataabout = {
    title: "About Me",
    aboutme: "I'm a student at Westcliff University, passionate about web development with growing experience in machine learning. I enjoy building digital solutions and exploring new technologies to create impactful user experiences.",
};

const worktimeline = [{
        jobtitle: "Student",
        where: "Westcliff University",
        date: "2023 - Present",
    },
    {
        jobtitle: "Freelancer",
        where: "Online",
        date: "2022 - Present",
    },
];

const skills = [{
        name: "Python",
        value: 20,
    },
    {
        name: "HTML/CSS",
        value: 40,
    },
    {
        name: "Figma",
        value: 60,
    },
    {
        name: "Machine Learning",
        value: 10,
    },

    {
        name: "JavaScript",
        value: 15,
    },
    {
        name: "React",
        value: 10,
    },
    {
        name: "SQL",
        value: 50,
    },
];

const services = [{
        title: "Data Science Solutions",
        description: "Machine learning models, predictive analytics, and data visualization for informed decision-making.",
    },
    {
        title: "Web Development",
        description: "Full-stack web applications using modern frameworks and responsive design principles.",
    },
    {
        title: "AI Integration",
        description: "Implementing AI/ML solutions in web applications for enhanced functionality and automation.",
    },
    {
        title: "Data Analytics",
        description: "Advanced data analysis and visualization to uncover insights and drive business decisions.",
    },
];

const dataportfolio = [{
        img: "https://picsum.photos/400/300/?grayscale",
        description: "NPL Nepali Sign Language Detection",
        link: "Yeah project ko github ko link",
    },
    {
        img: "https://picsum.photos/400/400/?grayscale",
        description: "Portfolio Website",
        link: "...................",
    },
    {
        img: "https://picsum.photos/400/500/?grayscale",
        description: "Live Bus Tracking System",
        link: "....................",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        description: "AI-Driven Recommendation Engine",
        link: "https://github.com/yourusername/project4",
    },
    // {
    //     img: "https://picsum.photos/400/700/?grayscale",
    //     description: "E-commerce Analytics Solution",
    //     link: "https://github.com/yourusername/project5",
    // },
    // {
    //     img: "https://picsum.photos/400/800/?grayscale",
    //     description: "Natural Language Processing API",
    //     link: "https://github.com/yourusername/project6",
    // },
];

const contactConfig = {
    YOUR_EMAIL: "khanal.anantu@gmail.com",
    // YOUR_FONE: "+977 XXXXXXXXX",
    description: "Interested in collaboration? Let's discuss how we can work together on your next project.",
    // YOUR_SERVICE_ID: "service_id",
    // YOUR_TEMPLATE_ID: "template_id",
    // YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com/anantakhanal465",
    linkedin: "https://www.linkedin.com/in/ananta-khanal-40a2742a8/",
    twitter: "https://x.com/AnantaKhan328",
    // kaggle: "https://kaggle.com/yourusername",
    // medium: "https://medium.com/@yourusername",
};

export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};